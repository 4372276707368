import { call, put, takeEvery } from "redux-saga/effects";
import {
  backTrackingAirportRequest,
  backTrackingAirportSuccess,
  backTrackingAirportFailure,
} from "../slice/backTrackingAirportSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.BACK_TRACKING_AIRPORT_API;
const fetchBackTrackingAirportDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.get(API_URL, headerData);

  return response;
};
function* fetchBackTrackingAirport(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchBackTrackingAirportDetail, payload);
    yield put(backTrackingAirportSuccess(response));
  } catch (error: any) {
    yield put(backTrackingAirportFailure(error?.response));
  }
}

export function* watchBackTrackingAirport() {
  yield takeEvery(backTrackingAirportRequest.type, fetchBackTrackingAirport);
}
