import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardReviewPnrCancelRequest,
  airAwardReviewPnrCancelSuccess,
  airAwardReviewPnrCancelFailure,
} from "../slice/airAwardReviewPnrCancelSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_REVIEW_PNR_CANCEL_API;
const fetchAirAwardReviewPnrCancelDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchAirAwardReviewPnrCancel(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardReviewPnrCancelDetail, payload);
    yield put(airAwardReviewPnrCancelSuccess(response));
  } catch (error: any) {
    yield put(airAwardReviewPnrCancelFailure(error?.response));
  }
}

export function* watchAirAwardReviewPnrCancel() {
  yield takeEvery(
    airAwardReviewPnrCancelRequest.type,
    fetchAirAwardReviewPnrCancel
  );
}
