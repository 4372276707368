import { call, put, takeLatest } from "redux-saga/effects";

import api from "../../../config/api";

import {
  airportDataRequest,
  getAirportDataSuccess,
  getAirportDataFailure,
} from "../slice/bookingWidgetSlice";

const AIRPORT_API_URL = "/common/airport/list";

function* getAirportData(action: ReturnType<typeof airportDataRequest>): any {
  try {
    let data = {
      mode: "no-cors",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Accept-Language": action.payload,
      },
      body: {},
    };
    const response: any = yield call(api.get, AIRPORT_API_URL, data);
    yield put(getAirportDataSuccess(response.data));
  } catch (error: any) {
    yield put(getAirportDataFailure(error.message));
  }
}

export function* watchAirportData(): any {
  yield takeLatest(airportDataRequest.type, getAirportData);
}
