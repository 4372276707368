import { call, put, takeEvery } from "redux-saga/effects";
import {
  changePasswordResendOTPFail,
  changePasswordResendOTPPending,
  changePasswordResendOTPSuccess,
} from "../slice/changePasswordOTPResendSlice";
import api from "../../../config/api";
import { ChangePasswordResendOTPData } from "../models/models";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.ROP_CHANGE_PASSWORD_OTP_RESEND_API;

const resendOTPData = async (payload: ChangePasswordResendOTPData) => {
  const headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website",
    },
  };
  let payloadData = {
    otpRefKey: payload.otpRefKey,
  };

  const response = await api.post(API_URL, payloadData, headerData);
  sessionStorage.setItem("refNumber", response.data.otpRefKey);
  return response;
};
function* changePasswordResendOTPSaga(
  action: PayloadAction<ChangePasswordResendOTPData>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(resendOTPData, payload);
    yield put(changePasswordResendOTPSuccess(response.data));
  } catch (error: any) {
    yield put(changePasswordResendOTPFail(error));
  }
}

export function* watchChangePasswordResendOTP() {
  yield takeEvery(
    changePasswordResendOTPPending.type,
    changePasswordResendOTPSaga
  );
}
