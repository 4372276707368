import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardAirportRequest,
  airAwardAirportSuccess,
  airAwardAirportFailure,
} from "../slice/airAwardAirportSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_AIRPORT_API;
const fetchAirAwardAirportDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.get(API_URL, headerData);

  return response;
};
function* fetchAirAwardAirport(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardAirportDetail, payload);
    yield put(airAwardAirportSuccess(response));
  } catch (error: any) {
    yield put(airAwardAirportFailure(error?.response));
  }
}

export function* watchAirAwardAirport() {
  yield takeEvery(airAwardAirportRequest.type, fetchAirAwardAirport);
}
