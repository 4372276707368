import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardTstRequest,
  airAwardTstSuccess,
  airAwardTstFailure,
} from "../slice/airAwardTstSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_TST_API;
const fetchAirAwardTstDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchAirAwardTst(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardTstDetail, payload);
    yield put(airAwardTstSuccess(response));
  } catch (error: any) {
    yield put(airAwardTstFailure(error?.response));
  }
}

export function* watchAirAwardTst() {
  yield takeEvery(airAwardTstRequest.type, fetchAirAwardTst);
}
