import { call, put, takeEvery } from "redux-saga/effects";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";
import {
  cancelBookingPNRSuccess,
  cancelBookingPNRRequest,
  cancelBookingPNRFail,
} from "../slice/cancelBookingPNRSlice";

const API_URL = config?.CANCEL_BOOKING_PNR;
const fetchCancelBookingPNR = async (payload: any) => {
  let headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website",
    },
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* cancelBookingPNRSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchCancelBookingPNR, payload);
    yield put(
      cancelBookingPNRSuccess({
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      cancelBookingPNRFail({
        ...error?.response,
      })
    );
  }
}

export function* watchCancelBookingPNRData() {
  yield takeEvery(cancelBookingPNRRequest.type, cancelBookingPNRSaga);
}
