import { AxiosResponse } from "axios";
import api from "../../../config/api";
import { call, put, takeEvery } from "redux-saga/effects";
import cmsApi from "../../../config/cmsApi";
import { config } from "../../../config/global";

export const FETCH_DATA_CONSTANT = "FETCH_DATA";

interface FetchDataParams<T> {
  url: string;
  isTeamsite?: boolean;
  //method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  //requestTransform?: (data?: any) => any
  successAction: (data: T) => { type: string; payload: T };
  errorAction: (error: Error) => { type: string; payload: Error };
}

function* fetchData<T>(action: FetchDataParams<T>) {
  try {
    //const { url, method = 'GET', requestTransform } = action;
    //const transformedData = requestTransform? requestTransform({}) : undefined;
    const response: AxiosResponse = yield call(
      action.isTeamsite ? cmsApi.get : api.get,
      action.url
    ); // ToDo: Need to evaluate whether config param (3rd one) to be passed as it can be handled in interceptor
    yield put(action.successAction(response.data));
  } catch (error: any) {
    yield put(action.errorAction(error));
  }
}

export function* watchFetchData() {
  yield takeEvery(config.FETCH_DATA_CONSTANT, fetchData);
}

export default watchFetchData;
