import { call, put, takeEvery } from "redux-saga/effects";
import {
  changePasswordDataRequest,
  changePasswordDataSuccess,
  changePasswordDataFailure,
} from "../slice/changePasswordSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";
import { ChangePasswordData } from "../models/models";

const API_URL = config?.ROP_CHANGE_PASSWORD_API;

const fetchChangePin = async (changePinPayload: ChangePasswordData) => {
  let data = {
    mode: "no-cors",
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
      Authorization: sessionStorage.getItem("accesstoken"),
    },
    isGenericErr: false,
  };
  let changePinPayloadData = {
    oldPin: changePinPayload.oldPin,
    newPin: changePinPayload.newPin,
  };

  const response = await api.post(API_URL, changePinPayloadData, data);
  if (response) {
    sessionStorage.setItem("refNumber", response?.data.otpRefKey);
    sessionStorage.setItem("newPin", changePinPayload.newPin);
  }
  return response;
};
function* fetchChangePinSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchChangePin, payload);

    yield put(
      changePasswordDataSuccess({
        ...response,
      })
    );
  } catch (error: any) {
    yield put(
      changePasswordDataFailure({
        ...error,
      })
    );
  }
}

export function* watchChangePin() {
  yield takeEvery(changePasswordDataRequest.type, fetchChangePinSaga);
}
