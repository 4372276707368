import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardPnrRequest,
  airAwardPnrSuccess,
  airAwardPnrFailure,
} from "../slice/airAwardPnrSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_PNR_API;
const fetchAirAwardPnrDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchAirAwardPnr(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardPnrDetail, payload);
    yield put(airAwardPnrSuccess(response));
  } catch (error: any) {
    yield put(airAwardPnrFailure(error?.response));
  }
}

export function* watchAirAwardPnr() {
  yield takeEvery(airAwardPnrRequest.type, fetchAirAwardPnr);
}
