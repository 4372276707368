import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardReviewPnrRequest,
  airAwardReviewPnrSuccess,
  airAwardReviewPnrFailure,
} from "../slice/airAwardReviewPnrSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_REVIEW_PNR_API;
const fetchAirAwardReviewPnrDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchAirAwardReviewPnr(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardReviewPnrDetail, payload);
    yield put(airAwardReviewPnrSuccess(response));
  } catch (error: any) {
    yield put(airAwardReviewPnrFailure(error?.response));
  }
}

export function* watchAirAwardReviewPnr() {
  yield takeEvery(airAwardReviewPnrRequest.type, fetchAirAwardReviewPnr);
}
