import { call, put, takeLatest } from "redux-saga/effects";
import api from "../../../config/api";
import {
  sendChangePasswordOTPPending,
  sendChangePasswordOTPSuccess,
  sendChangePasswordOTPFailure,
} from "../slice/changePasswordOTPSlice";
import { ChangePasswordFormData } from "../models/models";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.ROP_CHANGE_PASSWORD_SUBMIT_API;

const sendChangePasswordOTPData = async (otpData: ChangePasswordFormData) => {
  const data = {
    otpRefKey: otpData.otpRefKey,
    otp: otpData.otp,
    newPin: sessionStorage.getItem("newPin"),
  };
  const headerData = {
    headers: {
      Authorization: sessionStorage.getItem("accesstoken"),
      "Content-Type": "application/json",
      source: "website",
    },
  };
  const response = await api.post(API_URL, data, headerData);
  return response;
};

function* sendOTPData(
  action: PayloadAction<ChangePasswordFormData>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    const response = yield call(sendChangePasswordOTPData, payload);
    yield put(sendChangePasswordOTPSuccess(response.data));
  } catch (error: any) {
    yield put(sendChangePasswordOTPFailure(error));
  }
}

export function* watchChangePasswordSendOTPData(): any {
  yield takeLatest(sendChangePasswordOTPPending.type, sendOTPData);
}
