import { PayloadAction } from "@reduxjs/toolkit";
import api from "../../../config/api";
import { CalendarFareData } from "../models/models";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  sendCalendarFareData,
  sendCalendarFareFailure,
  sendCalendarFareSuccess,
} from "../slice/calendarPricingSlice";

const CALENDAR_FARE_API_URL = "common/calendarPricing";

const sendCalendarData = async (fare: CalendarFareData) => {
  const data = {
    journeyType: fare.journeyType,
    origin: fare.origin,
    destination: fare.destination,
    classes: fare.classes,
    dateRange: {
      startDate: fare.dateRange.startDate,
      endDate: fare.dateRange.endDate,
    },
    histogramType: fare.histogramType,
  };
  const HeaderData = {
    headers: {
      "content-type": "application/json",
    },
  };
  const response = await api.post(CALENDAR_FARE_API_URL, data, HeaderData);

  return response;
};

function* sendCalendarPriceData(
  action: PayloadAction<CalendarFareData>
): Generator<any, void, any> {
  const { payload } = action;
  try {
    // yield put(sendCalendarFareData());
    const response = yield call(sendCalendarData, payload);
    yield put(sendCalendarFareSuccess(response.data));
  } catch (error) {
    yield put(sendCalendarFareFailure(error));
  }
}

export function* watchSendCalendarPriceData(): any {
  yield takeEvery(sendCalendarFareData.type, sendCalendarPriceData);
}
