import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardReviewPnrRMRequest,
  airAwardReviewPnrRMSuccess,
  airAwardReviewPnrRMFailure,
} from "../slice/airAwardReviewPnrRMSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_REVIEW_PNR_RM_API;
const fetchAirAwardReviewPnrRMDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchAirAwardReviewPnrRM(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardReviewPnrRMDetail, payload);
    yield put(airAwardReviewPnrRMSuccess(response));
  } catch (error: any) {
    yield put(airAwardReviewPnrRMFailure(error?.response));
  }
}

export function* watchAirAwardReviewPnrRM() {
  yield takeEvery(airAwardReviewPnrRMRequest.type, fetchAirAwardReviewPnrRM);
}
