import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardPriceRequest,
  airAwardPriceSuccess,
  airAwardPriceFailure,
} from "../slice/airAwardPriceSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_PRICE_API;
const fetchAirAwardPriceDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchAirAwardPrice(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardPriceDetail, payload);
    yield put(airAwardPriceSuccess(response));
  } catch (error: any) {
    yield put(airAwardPriceFailure(error?.response));
  }
}

export function* watchAirAwardPrice() {
  yield takeEvery(airAwardPriceRequest.type, fetchAirAwardPrice);
}
