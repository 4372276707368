import { call, put, takeEvery } from "redux-saga/effects";
import {
  airAwardBookingRequest,
  airAwardBookingSuccess,
  airAwardBookingFailure,
} from "../slice/airAwardBookingSlice";
import api from "../../../config/api";
import { PayloadAction } from "@reduxjs/toolkit";
import { config } from "../../../config/global";

const API_URL = config?.AIR_AWARD_BOOKING_API;
const fetchAirAwardBookingDetail = async (payload: any) => {
  let headerData = {
    headers: {
      "content-type": "application/json",
      "Access-Control-Expose-Headers": "accessToken",
    },
    loader: true,
  };

  const response = await api.post(API_URL, payload, headerData);

  return response;
};
function* fetchAirAwardBooking(
  action: PayloadAction<any>
): Generator<any, void, any> {
  const { payload } = action;

  try {
    const response = yield call(fetchAirAwardBookingDetail, payload);
    yield put(airAwardBookingSuccess(response));
  } catch (error: any) {
    yield put(airAwardBookingFailure(error?.response));
  }
}

export function* watchAirAwardBooking() {
  yield takeEvery(airAwardBookingRequest.type, fetchAirAwardBooking);
}
