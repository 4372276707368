import { call, put, takeEvery } from "redux-saga/effects";
import {
  getApplicationCardDataSuccess,
  getApplicationCardDataError,
  getApplicationCardDataRequest,
} from "../slice/applicationCardSlice";
import axios from "axios";

const API_URL = "/json/applicationCard.json";

function* fetchApplicationCardSaga(): any {
  try {
    const response = yield call(axios.get, API_URL, { baseURL: "/" });
    yield put(getApplicationCardDataSuccess(response.data));
  } catch (error: any) {
    yield put(getApplicationCardDataError(error.message));
  }
}

export function* watchFetchApplicationCardData(): any {
  yield takeEvery(getApplicationCardDataRequest.type, fetchApplicationCardSaga);
}
